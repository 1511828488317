import React from "react"
import Layout from '../layouts/layout'
import {Link, useTranslation} from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Style from './blog.module.scss'
import Title from "../components/Title";

export const query = graphql`
    query ($language: String) {
        allMarkdownRemark(limit: 20, sort: {order: DESC, fields: frontmatter___date}, filter: {frontmatter: {language: {eq: $language}, type: {eq: "blog"}}}) {
            edges {
                node {
                frontmatter {
                    language
                    title
                    date(formatString: "DD MMMM YYYY", locale: $language)
                    slug
                    description
                }
                timeToRead
                html
                }
            }
        },
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
              node {
                ns
                data
                language
              }
            }
          
        }
    }
    `

const Blog = (props) => {
    const {t} = useTranslation();
    const posts = [];
    props.data.allMarkdownRemark.edges.forEach(edge => posts.push(

            <Link to={'/blog/'+edge.node.frontmatter.slug} className={Style.card} key={edge.node.frontmatter.title}>
                <h2 className={Style.card_title}>{edge.node.frontmatter.title}</h2>
                <span className={Style.card_description}>{edge.node.frontmatter.description}</span>

                <div className={Style.card_bottom}>
                    <small className={Style.card_date}>{edge.node.frontmatter.date}</small>
                    <small className={Style.card_btn}>En savoir plus</small>
                </div>
            </Link>

    ))

    return (
        <div>
            <Layout>
                <Helmet>
                    <title>{t("blog_title")}</title>
                </Helmet>
                <Title translation="blog_title"/>
                <div className={Style.wrapper}>
                    <div className={Style.list}>    
                        {posts}
                    </div>
                </div>
            </Layout>
        </div>
    );
}

export default Blog
